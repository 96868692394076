import { AuthCtx, useContextState } from '@components/contexted';
import { Layout } from '@components/layout';
import { PageHeader } from '@components/shared';
import { LoginForm, UserDetails } from '@components/standard';
import type { IPage, ILocation } from '@standardTypes';
import React from 'react';

interface LoginPageProps {
  pageContext: {
    page: IPage;
  };
  location: ILocation;
}

const LoginPage: React.FC<LoginPageProps> = ({ pageContext }) => {
  const { page } = pageContext;
  const data = useContextState(AuthCtx);

  return (
    <Layout {...page}>
      <PageHeader
        title={data.loggedIn === true ? 'Dane konta' : 'Logowanie'}
        isTransparent
      />
      {data.loggedIn === true ? <UserDetails /> : <LoginForm />}
    </Layout>
  );
};

export default LoginPage;
